<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="d-flex flex-column form-user-info">
      <div class="top-message-block">
        <slot name="top-message-block"></slot>
      </div>
      <div class="form">
        <div class="row">
          <div class="col-sm-12 py-3 top-message-block" v-if="showTopMessageBlock">This user has been invited by email and has not yet accepted the invitation.
            Click Resend invite to send the invitation again.</div>
          <div class="col-sm-12 title">General info</div>
          <div class="col-sm-12">
            <b-form class="mt-4">
              <div role="group" class="form-group-input">
                <label for="user-info-firstname">First name</label>
                <b-form-input
                    id="user-info-firstname"
                    v-model="form.firstname"
                    type="text"
                    :state="validateState('firstname')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  <div v-if="!$v.form.firstname.maxLength">Must not be at more 255 characters</div>
                  <div v-else-if="!$v.form.firstname.required">Cannot be empty</div>
                  <div v-else>The firstname is incorrect</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-info-lastname">Last name</label>
                <b-form-input
                    id="user-info-lastname"
                    v-model="form.lastname"
                    type="text"
                    :state="validateState('lastname')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  <div v-if="!$v.form.lastname.maxLength">Must not be at more 255 characters</div>
                  <div v-else-if="!$v.form.lastname.required">Cannot be empty</div>
                  <div v-else>The lastname is incorrect</div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-info-email">Email</label>
                <b-form-input
                    id="user-info-email"
                    v-model="form.email"
                    type="text"
                    :state="validateState('email')"
                    :disabled="mode === 'editing'"
                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  Email address is incorrect
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-info-phone-number">Phone number</label>
                <b-form-input
                    id="user-info-phone-number"
                    v-model="form.phone"
                    type="text"
                    :state="validateState('phone')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-firstname-feedback">
                  Phone number is incorrect
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-3">
                <label for="user-info-clients">Client</label>
                <multiselect
                    v-model="form.clients"
                    track-by="id" label="name"
                    :options="listWhereCanInvite"
                    :multiple="true"
                    placeholder="Select"
                    :class="{'is-invalid': isClientInvalid}"
                ></multiselect>
                <b-form-invalid-feedback id="input-clients-feedback">
                  Client cannot be empty
                </b-form-invalid-feedback>
              </div>

              <div class="permissions mt-3">

                <div class="permission-presets d-inline-flex justify-content-between align-items-center px-1 mt-1">
                  <div class="preset" :class="{active: chosenPreset === key}" :key="key"
                       v-for="(preset, key) in permissionsPresets"
                       v-on:click="changePreset(key)">
                    {{ getLabel(key) }}
                  </div>
                  <div class="preset" :class="{active: chosenPreset === 'custom'}"
                       v-on:click="changePreset('custom')">
                    Custom
                  </div>
                </div>


                <div class="single-permission" v-if="chosenPreset === 'full'">
                  <b-form-checkbox
                      id="manage_users"
                      v-model="chosenPermissions.manage_users"
                      @change="changePermissionsHandler('manage_users')"
                      name="manage_users">
                    Can invite other users
                  </b-form-checkbox>
                </div>


                <div class="single-permission" :key="permissionsGroupName"
                     v-for="(permissionsGroup, permissionsGroupName, i) in userPermissions">
                  <div class="header-single-permission align-items-center d-flex justify-content-between">
                    <div class="label-permission">
                      {{getLabel(permissionsGroupName)}}
                    </div>
                    <div class="number-permission">
                      <svg width="140" height="2" viewBox="0 0 140 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="140" height="1" fill="#D9DDD9"/>
                      </svg>
                      <span class="ml-2">{{++i}}/{{Object.keys(userPermissions).length}}</span>
                    </div>
                  </div>

                  <div class="body-single-permission">
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  v-for="permission in permissionsGroup" :key="permission">
                      <b-form-checkbox
                          :class="{disabled: isDisabled(permission)}"
                          v-model="chosenPermissions[permission]"
                          :aria-describedby="ariaDescribedby"
                          name="messaging.sensitive_docs"
                          :disabled="isDisabled(permission)"
                          @change="changePermissionsHandler(permission)"
                      > {{ getLabel(permission) }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>

                </div>
              </div>


            </b-form>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>

</template>

<script>
import _ from "lodash";
import {validationMixin} from 'vuelidate';
import {required, email, maxLength} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import userPermissions from "@/types/user_permissions";
import permissionsPresets from "@/types/user_permissions_presets";
import permissionsLabels from "@/types/user_permissions_labels";
import permissionsDependencies from "@/types/user_permissions_dependencies";

export default {
  name: "UserInfoForm",
  mixins: [validationMixin],
  components: {Multiselect},
  data() {
    return {
      userPermissions: userPermissions.list,
      lockedPermissions: userPermissions.lockedPermissions,
      permissionsPresets,
      permissionsLabels,
      chosenPermissions: this.initPermissions(),
      claimPackDisable: false,
      form: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        clients: null,
      },
      permissions: {},
      chosenPreset: null,
      preset: 'view_only_access',
      loading: false,

    }
  },
  props:['mode'],
  computed: {
    ...mapGetters('user/client', ['listWhereCanInvite']),
    ...mapGetters('user/editing', {
      editingUser: 'user',
      editingUserPermissions: 'permissions'
    }),
    ...mapGetters('user/permission', ['availablePermissions']),
    ...mapGetters(['hasError', 'error']),

    isClientInvalid: function () {
      let validation = this.validateState('clients');
      return validation === null ? false : !validation;
    },
    showTopMessageBlock: function () {
      if (this.mode !== 're-invite') {
        return false
      }
      return !this.editingUser.is_active;
    }
  },
  validations: {
    form: {
      firstname: {
        required, maxLength: maxLength(255)
      },
      lastname: {
        required, maxLength: maxLength(255)
      },
      email: {
        required, email, maxLength: maxLength(255)
      },
      phone: {},
      clients: {
        required
      }
    }
  },
  methods: {
    ...mapActions('invitation', ['sendInvitation']),
    ...mapActions('user/editing', ['updateUser']),
    ...mapActions('notifications', ['addNotification']),
    getLabel(key) {
      return this.permissionsLabels[key];
    },
    initPermissions() {
      let result = {};

      for (let permissionsGroup in userPermissions.list) {

        for (let key in userPermissions.list[permissionsGroup]) {
          const permission = userPermissions.list[permissionsGroup][key]

          result[permission] = (userPermissions.lockedPermissions[permission] !== undefined) ?
                               userPermissions.lockedPermissions[permission] : false;
        }
      }

      result.manage_users = false;

      return result;
    },
    isDisabled(permission) {
      //
      if (permission === 'final_claim.read' ||
          permission === 'final_claim.approve') {
        return !this.chosenPermissions['sensitive_docs.read'];
      }

      if (permission === 'final_claim.no_access') {
        return true;
      }

      if (userPermissions.lockedPermissions[permission] === true) {
        return true;
      }

      return false;
    },
    showLoading() {
      this.loading = true;
    },
    changePermissionsHandler(changedPermission = null) {

      if (changedPermission !== null) {
        const changedPermissionState = this.chosenPermissions[changedPermission];

        if (changedPermissionState === true) {
          if (this.chosenPermissions[changedPermission] && permissionsDependencies[changedPermission] !== undefined) {
            for (let dependentPermission of permissionsDependencies[changedPermission]) {
              this.chosenPermissions[dependentPermission] = true;
            }
          }
        } else {
          for (let dependentPermission in permissionsDependencies) {
            // if changed permission has dependent permissions
            if (permissionsDependencies[dependentPermission].indexOf(changedPermission) > -1) {
              // unselect them
              this.chosenPermissions[dependentPermission] = false;
            }
          }
        }
      }

      // get list of chosen permissions
      let activePermissions = [];
      for (let permission in this.chosenPermissions) {
        if (this.chosenPermissions[permission])
          activePermissions.push(permission);
      }

      this.chosenPermissions['final_claim.no_access'] = !this.chosenPermissions['final_claim.read'] && !this.chosenPermissions['final_claim.approve'];

      // check for presets
      for (let preset in this.permissionsPresets) {
        if (_.isEqual(this.permissionsPresets[preset].sort(), activePermissions.sort())) {
          // chosen permissions are equal to preset
          this.chosenPreset = preset;
          return;
        }
      }

      // no preset chosen, 'custom' should be active
      this.chosenPreset = 'custom';

    },
    hideLoading() {
      this.loading = false;
    },
    setDefaultData() {
      this.form = {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        clients: null,
      };
      this.permissions = _.cloneDeep(this.viewAssessPermissions);
      this.preset =  'view_only_access';
      this.$v.form.$reset();
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError || this.loading) {
        return;
      }
      if (this.mode === 'invitation') {
        this.submitInvitation(this.mode);
      }
      if (this.mode === 'editing') {
        this.submitEditing();
      }
      if (this.mode === 're-invite') {
        this.submitInvitation();
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    changePreset(preset) {

      this.chosenPreset = preset;
      const permissionsPreset = this.permissionsPresets[preset]

      // const manageUsersPermissionState = this.chosenPermissions.manage_users;

      if (permissionsPreset !== undefined) {
        this.chosenPermissions = {};
        permissionsPreset.forEach(permission => {
          this.chosenPermissions[permission] = true;
        })
        this.changePermissionsHandler();
      }

      // this.chosenPermissions.manage_users = manageUsersPermissionState;

    },
    setFullAccess() {
      this.permissions = _.cloneDeep(this.fullAccessPermissions);
    },
    setViewAccess() {
      this.permissions = _.cloneDeep(this.viewAssessPermissions);
    },
    submitInvitation(mode) {
      let permissions = [];

      if (this.chosenPreset !== 'full') {
        this.chosenPermissions.manage_users = false;
      }

      for (let permission in this.chosenPermissions) {
        if (this.chosenPermissions[permission])
          permissions.push(permission);
      }

      let form = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email,
        organizations: this.form.clients.map(function (client) {
          return client.id;
        }),
        permissions,
        mode
      };

      if (!_.isEmpty(this.form.phone)) {
        form.phone = this.form.phone;
      }
      if (!_.isEmpty(this.form.position)) {
        form.position = this.form.position.key;
      }
      this.showLoading();

      this.sendInvitation(form).then((isSendInvitation) => {
        this.hideLoading();
        if (isSendInvitation) {
          this.$emit('invitation-sent');
        }
      });
    },
    submitEditing() {

      if (this.chosenPreset !== 'full') {
        this.chosenPermissions.manage_users = false;
      }

      let permissions = [];

      for (let permission in this.chosenPermissions) {
        if (this.chosenPermissions[permission])
          permissions.push(permission);
      }

      const clientIds = [];
      this.form.clients.forEach(function (client) {
        clientIds.push(client.id);
      })

      let form = {
        userId: this.editingUser.id,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email,
        permissions: permissions,
        organizations: clientIds
      };

      if (!_.isEmpty(this.form.phone)) {
        form.phoneNumber = this.form.phone
      }

      this.showLoading();

      this.updateUser(form).then((response) => {
        this.hideLoading();
        if (response.status === 200) {
          this.$emit('user-updated');
          this.addNotification({
            title: 'User info',
            message: 'The account details for user ' + form.firstname + ' ' + form.lastname + ' have been updated',
            type: 'success'
          })
        }
      })


    }
  },
  watch: {
    editingUser: function () {
      if (this.mode === 'editing' || this.mode === 're-invite') {
        this.form = {
          firstname: this.editingUser.firstname,
          lastname: this.editingUser.lastname,
          email: this.editingUser.email,
          position: this.editingUser.position,
          phone: this.editingUser.phone_number,
          clients: this.editingUser.organizations,
        };

        const userPermissions = this.editingUser.permissions.map(permission => permission.permission)

        for (const permission in this.chosenPermissions) {
          if (userPermissions.indexOf(permission) > -1) {
            this.chosenPermissions[permission] = true;
          } else {
            this.chosenPermissions[permission] = false;
          }
        }

        this.changePermissionsHandler();
      }
    }
  }
}
</script>

<style scoped>

</style>