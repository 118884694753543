// all permissions by group
const list = {
  claim: {
    all: 'claim.all',
    open: 'claim.open',
  },
  non_sensitive_docs: {
    // no_access: 'non_sensitive_docs.no_access',
    read: 'non_sensitive_docs.read',
    update: 'non_sensitive_docs.update',
  },
  sensitive_docs: {
    // no_access: 'sensitive_docs.no_access',
    read: 'sensitive_docs.read',
    update: 'sensitive_docs.update',
  },
  messaging: {
    sensitive_docs: 'messaging.sensitive_docs',
    non_sensitive_docs: 'messaging.non_sensitive_docs',
  },
  final_claim: {
    no_access: 'final_claim.no_access',
    read: 'final_claim.read',
    approve: 'final_claim.approve',
  },
}

// locked permissions with state
const lockedPermissions = {
  'claim.open': true,
  'non_sensitive_docs.read': true,
}


export default {
  list,
  lockedPermissions,
}


