
export default {
    data() {
        return {
            refName: "table",
            isBusy: false,
            pagination: {
                perPage: 15,
                currentPage: +this.$route.query.page || 1,
                totalRows: (+this.$route.params.page || +this.$route.query.page || 1) * 15,
            },
            sorting: {
                sortKey: this.$route.query.orderField || '',
                sortDesc: !!(this.$route.query.orderDirection && this.$route.query.orderDirection === 'desc'),
            },
        }
    },
    watch: {
        'pagination.currentPage': {
            handler() {
                this.tableRefresh();
            },
            deep: true,
        },
    },
    methods: {
        tableRefresh() {
            !!this.$refs[this.refName] && this.$refs[this.refName].refresh();
        },
        sortingChanged(ctx) {
            this.sorting.sortKey = ctx.sortBy;
            this.sorting.sortDesc = ctx.sortDesc;
        },
    },
}