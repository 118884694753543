export default {
    'claim.all': 'View all claims',
    'claim.open': 'View only open claims',
    'non_sensitive_docs.read': 'Can read',
    'non_sensitive_docs.update': 'Upload / Delete / Replace documents',
    'sensitive_docs.read': 'Can read',
    'sensitive_docs.update': 'Upload / Delete / Replace documents',
    'messaging.sensitive_docs': 'Sensitive documents',
    'messaging.non_sensitive_docs': 'Non-sensitive documents',
    'final_claim.read': 'Can view',
    'final_claim.approve': 'Can read and approve',
    'share_documents': 'Can share from final claim pack',
    // 'receive.non_sensitive_docs': 'Can receive a non-sensitive shared document ',
    // 'receive.sensitive_docs': 'Can receive a sensitive shared document',
    'final_claim.no_access': 'No access',


    'full': 'Full access',
    'unrestricted': 'Unrestricted',
    'restricted': 'Restricted',

    'claim': 'Claim',
    'non_sensitive_docs': 'Non-sensitive documents',
    'sensitive_docs': 'Sensitive documents',
    'messaging': 'Messaging',
    'final_claim': 'Final claim pack',

    // 'sharing_documents': 'Share documents',

}