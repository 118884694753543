<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>Users</template>
      </TopBar>
    </template>
    <template v-slot:content>
      <div class="content flex-grow-1">

      <div class="d-flex top-panel mt-3 align-items-center user-list">
        <div class="flex-grow-1 filter client-filter">
          <multiselect
              v-model="clientFilterValue"
              :options="listWhereCanInvite"
              :multiple="true"
              track-by="id" label="name"
              placeholder="Select client"
              v-on:input="clientFilterValueInputHandler"
          ></multiselect>
        </div>
        <div class="d-flex align-items-center">
          <div class="search">
            <b-input-group>
              <b-form-input
                  v-model="search"
                  placeholder="Search"
                  v-on:input="searchValueInputHandler"
              >
              </b-form-input>
              <template #prepend>
                <b-input-group-text><SearchIcon /></b-input-group-text>
              </template>
            </b-input-group>
          </div>
          <div class="invite-user-btn" v-if="canInvite">
            <b-button
                variant="primary"
                v-on:click="inviteUserHandler">
              <InviteUserIcon />
              Invite user
            </b-button>
          </div>
        </div>
      </div>
      <div class="d-flex table-users flex-column mt-3">
        <b-table
            striped
            :ref="refName"
            :items="provider"
            :fields="fields"
            :sort-by.sync="sorting.sortKey"
            :sort-desc.sync="sorting.sortDesc"
            v-on:sort-changed="sortingChanged">
          <template #cell(email)="data">
            <span class="email">{{data.value}}</span>
          </template>
          <template #cell(phone_number)="data">
            <span class="phone-number">{{data.value}}</span>
          </template>
          <template #cell(permissions_preset)="data">
            <span class="permissions_preset">
              {{ data.value }}
            </span>
          </template>
          <template #cell(organizations)="data">
            {{ formatClientsList(data.value) }}
          </template>
          <template #cell(actions)="data">
            <b-dropdown id="dropdown-dropleft" dropleft class="actions">
              <template #button-content>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99998 8.66671C8.36817 8.66671 8.66665 8.36823 8.66665 8.00004C8.66665 7.63185 8.36817 7.33337 7.99998 7.33337C7.63179 7.33337 7.33331 7.63185 7.33331 8.00004C7.33331 8.36823 7.63179 8.66671 7.99998 8.66671Z" fill="#E83B2C" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.99998 3.99996C8.36817 3.99996 8.66665 3.70148 8.66665 3.33329C8.66665 2.9651 8.36817 2.66663 7.99998 2.66663C7.63179 2.66663 7.33331 2.9651 7.33331 3.33329C7.33331 3.70148 7.63179 3.99996 7.99998 3.99996Z" fill="#E83B2C" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.99998 13.3333C8.36817 13.3333 8.66665 13.0349 8.66665 12.6667C8.66665 12.2985 8.36817 12 7.99998 12C7.63179 12 7.33331 12.2985 7.33331 12.6667C7.33331 13.0349 7.63179 13.3333 7.99998 13.3333Z" fill="#E83B2C" stroke="#E83B2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </template>
              <b-dropdown-item
                  v-if="!data.item.is_active"
                  v-on:click="resendInviteHandler(data)"
              >Resend invite </b-dropdown-item>
              <b-dropdown-item
                  v-on:click="editDetailsHandler(data)"
              >Edit details</b-dropdown-item>
              <b-dropdown-item
                  v-on:click="deleteUserHandler(data)"
                  :disabled="data.item.id == authUser.id"
              >Delete</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
            v-if="showPagination"
            v-model="pagination.currentPage"
            :total-rows="providerPagination.totalRows || pagination.totalRows"
            :per-page="pagination.perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            class="align-self-end"
            aria-controls="my-table"
        ></b-pagination>
        <MsgBox ref="deleteUserMsgBox" title="Confirm delete" ok-title="Yes, delete" message="Are you sure you want to delete this user?" />
      </div>
      </div>
    </template>
    <template v-slot:panel>
      <RightSidePanel ref="rightSidePanel" v-on:click-action="rightSidePanelClickActionHandler">
        <template v-slot:title>
          <span v-if="rightSidePanelMode==='invitation'">Invite user</span>
          <span v-if="rightSidePanelMode==='re-invite'">Resend invite</span>
          <span v-if="rightSidePanelMode==='editing'">User info</span>
        </template>
        <template v-slot:content>
          <UserInfoForm
              ref="userInfoForm"
              v-on:invitation-sent="invitationSentHandler"
              v-on:user-updated="userUpdatedHandler"
              :mode="rightSidePanelMode"
          ></UserInfoForm>
        </template>
        <template v-slot:action-title>
          <span v-if="rightSidePanelMode==='invitation'">Invite user</span>
          <span v-if="rightSidePanelMode==='re-invite'">Resend invite</span>
          <span v-if="rightSidePanelMode==='editing'">Save changes</span>
        </template>
      </RightSidePanel>
    </template>
  </Workspace>
</template>

<script>
import _ from 'lodash';
import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";
import RightSidePanel from "../layouts/workspace/RightSidePanel";
import UserInfoForm from "./parts/users/UserInfoForm";
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from "vuex";
import table from '../mixins/table.mixin';
import MsgBox from "../components/MsgBox";
import InviteUserIcon from "../components/icons/InviteUserIcon";
import SearchIcon from "../components/icons/SearchIcon";

export default {
  name: "Users",
  components: {SearchIcon, InviteUserIcon, MsgBox, Workspace, TopBar, Multiselect, RightSidePanel, UserInfoForm},

  data() {
    return {
      clientFilterValue: [],
      search: null,
      fields: [
        {
          key: 'firstname',
          label: 'Name',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Surname',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'phone_number',
          label: 'Phone number',
          sortable: true
        },
        {
          key: 'permissions_preset',
          label: 'Permissions',
        },
        {
          key: 'organizations',
          label: 'Client',
        },
        {
          key: 'actions',
          label: '',
        }
      ],
      rightSidePanelMode: null,
    }
  },
  mixins:[table],
  computed: {
    ...mapGetters('auth/user', {authUser: 'user', canInvite: 'canInvite'}),
    ...mapGetters('user/provider', {providerItems: 'items', providerPagination: 'pagination', }),
    ...mapGetters('user/client', ['listWhereCanInvite']),
    showPagination() {
      return this.providerPagination.totalRows > this.providerPagination.perPage;
    },
  },
  mounted() {
    this.fetchClientsWhereCanInvite();
    this.fetchPositions();
  },
  methods: {
    ...mapActions('user/provider', ['fetchProvider']),
    ...mapActions('user/client', ['fetchClientsWhereCanInvite']),
    ...mapActions('user/position', ['fetchPositions']),
    ...mapActions('user/editing', {fetchEditingUser: 'fetchUser'}),
    ...mapActions('user', ['deleteUser']),
    async provider() {
      let queryParams = {
        page: this.pagination.currentPage,
        itemPerPage: this.pagination.perPage,
        orderField: this.sorting.sortKey,
        orderDirection: this.sorting.sortDesc ? 'desc' : 'asc',

      };

      if (!_.isEmpty(this.clientFilterValue)) {
        queryParams.filter = {
          organizations: this.clientFilterValue.map(function (client) {
            return client.id;
          })
        }
      }

      if (!_.isEmpty(this.search)) {
        if (_.isEmpty(queryParams.filter)) {
          queryParams.filter = {
            search: this.search
          };
        }else{
          queryParams.filter.search = this.search;
        }
      }

      queryParams.filter = JSON.stringify(queryParams.filter);

      await this.fetchProvider(queryParams);

      let items = this.providerItems;
      

      return items
    },
    inviteUserHandler() {
      this.rightSidePanelMode = 'invitation';
      this.$refs.userInfoForm.setDefaultData();
      this.$refs.rightSidePanel.show();
    },
    rightSidePanelClickActionHandler() {
      this.$refs.userInfoForm.submit();
    },
    invitationSentHandler() {
      this.$refs.rightSidePanel.hide();
      this.$refs.userInfoForm.setDefaultData();
      this.tableRefresh();
    },
    formatClientsList(value) {
      if (!_.isArray(value) && !_.isObject(value)) {
        return
      }
      if (_.isArray(value)) {
        return value.map(function (client) {
          return client.name;
        }).join(', ');
      }
      if (_.isObject(value)) {
        return Object.keys(value).map(function (key) {
          return value[key].name;
        }).join(', ');
      }

    },
    clientFilterValueInputHandler() {
      this.tableRefresh();
    },
    searchValueInputHandler() {
      this.tableRefresh();
    },
    editDetailsHandler(data) {
      this.rightSidePanelMode = 'editing';
      this.$refs.rightSidePanel.show();
      this.$refs.userInfoForm.showLoading();
      this.fetchEditingUser(data.item.id).then(() => {
        this.$refs.userInfoForm.hideLoading();
      });

    },
    userUpdatedHandler() {
      this.$refs.rightSidePanel.hide();
      this.$refs.userInfoForm.setDefaultData();
      this.tableRefresh();
    },
    deleteUserHandler(data) {
      this.$refs.deleteUserMsgBox.show()
          .then((value) => {
            if (value === true) {
              this.deleteUser(data.item.id).then(() => {
                this.tableRefresh();
              })
            }
          })
          .catch(() => {

          })
    },
    resendInviteHandler(data) {
      this.rightSidePanelMode = 're-invite';
      this.$refs.rightSidePanel.show();
      this.$refs.userInfoForm.showLoading();
      this.fetchEditingUser(data.item.id).then(() => {
        this.$refs.userInfoForm.hideLoading();
      });
    }
  }
}
</script>

<style scoped>

</style>