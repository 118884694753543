export default {

    // permissions that should be activated automatically, e.g.:
    // docs.update -> docs.read

    'non_sensitive_docs.update': [
        'non_sensitive_docs.read',
    ],

    'sensitive_docs.update': [
        'sensitive_docs.read',
    ],

    'final_claim.approve': [
        'final_claim.read',
        'sensitive_docs.read'
    ],

    'final_claim.read': [
        'sensitive_docs.read'
    ],

    'messaging.sensitive_docs': [
        'sensitive_docs.read',
        'messaging.non_sensitive_docs',
    ],



}