export default {

    full: [
        'manage_users',
        'claim.all',
        'claim.open',
        'non_sensitive_docs.read',
        'non_sensitive_docs.update',
        'sensitive_docs.read',
        'sensitive_docs.update',
        'messaging.sensitive_docs',
        'messaging.non_sensitive_docs',
        'final_claim.read',
        'final_claim.approve',
        // 'share_documents',
        // 'receive.non_sensitive_docs',
        // 'receive.sensitive_docs',
    ],
    restricted: [
        'claim.open',
        'non_sensitive_docs.read',
        'non_sensitive_docs.update',
        'messaging.non_sensitive_docs',
        'final_claim.no_access',
        // 'receive.non_sensitive_docs',
    ],
    unrestricted: [
        'claim.open',
        'non_sensitive_docs.read',
        'non_sensitive_docs.update',
        'sensitive_docs.read',
        'sensitive_docs.update',
        'messaging.sensitive_docs',
        'messaging.non_sensitive_docs',
        'final_claim.no_access',
        // 'receive.non_sensitive_docs',
        // 'receive.sensitive_docs',
    ],



}